'use client';

import * as React from 'react';
import Link from 'next/link';

import { cmsLogo, cn } from '@/lib/utils';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu';

import Image from 'next/image';
import { StoreIcon } from 'lucide-react';
import { NavConfiguration, SiteConfiguration } from '@repo/api-types/generated';
import { IconComponent } from '../cms/icon-component';

export default function MegaMenu({
  className,
  listClassName,
  viewportClassName,
  siteConfiguration,
  navConfiguration,
}: {
  className?: string;
  listClassName?: string;
  viewportClassName?: string;
  siteConfiguration: SiteConfiguration;
  navConfiguration: NavConfiguration;
}) {
  const navData = navConfiguration.attributes;
  const logo = cmsLogo(siteConfiguration, 50);

  const navMenuTriggerClassName = cn(
    navigationMenuTriggerStyle(),
    'font-semibold ml-1',
    'bg-gray-100 dark:bg-gray-950',
    'border border-primary/50',
    'hover:bg-primary/10 dark:hover:bg-primary/15',
    'focus:bg-primary/10 dark:focus:bg-primary/15',
  );

  return (
    <NavigationMenu className={cn('mgsm-mega-menu', className)} viewportClassName={viewportClassName}>
      <NavigationMenuList className={cn(listClassName)}>
        {!navData.about.hidden && <NavigationMenuItem className='mgsm-mega-menu-about'>
          <NavigationMenuTrigger className='font-bold'>
            {navData.about.title}
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <NavigationMenuLink asChild>
                  <Link
                    className={cn(
                      'flex h-full w-full select-none flex-col justify-end rounded-md p-6 no-underline outline-none focus:shadow-md',
                      'bg-primary/15 dark:bg-primary bg-gradient-to-br from-secondary/50 dark:from-secondary to-primary/50 dark:to-primary',
                    )}
                    href={navData.about.cardURL}
                    target={navData.about.cardURL.startsWith('http') ? '_blank' : undefined}
                    rel={navData.about.cardURL.startsWith('http') ? 'noopener noreferrer' : undefined}
                  >
                    {logo && <Image className="h-14 w-14"
                      src={logo.url}
                      alt="Logo"
                      width={logo.width}
                      height={logo.height}
                    />}
                    <div className="mb-2 mt-4 text-xl font-extrabold text-white">
                      {navData.about.cardTitle}
                    </div>
                    <p className="text-sm leading-tight font-bold text-gray-200 dark:text-gray-300">
                      {navData.about.cardDescription}
                    </p>
                  </Link>
                </NavigationMenuLink>
              </li>

              {navData.about.items.map((item, ind) => (
                <ListItem key={`${item.title}-${ind}`} href={item.url} title={item.title} icon={item.icon} target={item.target} disabled={item.disabled}>
                  {item.description ?? ''}
                </ListItem>
              ))}

              {/* Force full width last item, adding a second list seems to mess with animations */}
              {siteConfiguration.attributes.socials.length > 0 && (<li className="mt-3 col-span-2 flex flex-row items-center justify-center bg-primary/15 rounded-md">
                {siteConfiguration.attributes.socials.map((social) => (
                  <Link
                    key={social.title}
                    href={social.url}
                    target={social.target}
                    rel={social.target === '_blank' ? 'noopener noreferrer' : undefined}
                    className={cn(
                      'select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground border border-transparent hover:border-primary',
                      social.disabled && 'opacity-50 cursor-not-allowed',
                      'grow w-full flex items-center justify-center',
                      className
                    )}
                    onClick={(e) => {
                      if (social.disabled) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <div className="text-base font-bold leading-none flex gap-1 items-center">
                      {social.icon && <IconComponent icon={social.icon} size={16} className='h-4 w-4 text-primary mt-0.5' />}
                      {social.title}
                    </div>
                  </Link>
                ))}
              </li>)}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>}
        {!navData.explore.hidden && <NavigationMenuItem className='mgsm-mega-menu-explore'>
          <NavigationMenuTrigger className='font-bold'>
            {navData.explore.title}
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <p className={cn(
              'p-4 text-xl font-bold text-center',
              'bg-primary text-white',
              'flex flex-row gap-2 items-center justify-center'
            )}>
              <IconComponent icon={navData.explore.bannerIcon} size={24} className='h-6 w-6 text-white' />
              {navData.explore.bannerText}
            </p>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {navData.explore.items.map((component) => {
                return (
                  <ListItem
                    key={component.title}
                    title={component.title}
                    href={component.url}
                    className=''
                    icon={component.icon}
                    target={component.target}
                    disabled={component.disabled}
                  >
                    {component.description ?? ''}
                  </ListItem>
                );
              })}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>}
        {!navData.storeButtonHidden && <NavigationMenuItem className='mgsm-mega-menu-store'>
          <Link href={siteConfiguration.attributes.storeOnlyMode ? '/' : '/store'} legacyBehavior passHref>
            <NavigationMenuLink className={cn(
              navMenuTriggerClassName,
            )}>
              <StoreIcon className='h-5 w-5 mr-2 text-primary' />
              Store
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>}
        {navData.additionalButtons.map((button) => (
          <NavigationMenuItem key={button.title} className='mgsm-mega-menu-additional'>
            <Link href={button.url} legacyBehavior passHref>
              <NavigationMenuLink target={button.target} className={cn(
                navMenuTriggerClassName,
                button.disabled && 'opacity-50 cursor-not-allowed',
              )}>
                {button.icon && <IconComponent icon={button.icon} size={16} className='h-5 w-5 mr-2 text-primary' />}
                {button.title}
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'> & {
    href: string;
    icon?: string;
    disabled?: boolean;
  }
>(({ className, href, title, icon, children, disabled = false, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          href={href}
          ref={ref}
          className={cn(
            'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground border border-transparent hover:border-primary',
            disabled && 'opacity-50 cursor-not-allowed',
            className
          )}
          onClick={(e) => {
            if (disabled) {
              e.preventDefault();
            }
          }}
          {...props}
        >
          <div className="text-base font-bold leading-none flex gap-1 items-center">
            {icon && <IconComponent icon={icon} size={16} className='h-4 w-4 text-primary mt-0.5' />}
            {title}
          </div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </Link>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = 'ListItem';
