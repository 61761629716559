'use client';

import { cn } from '@/lib/utils';
import loadable from '@loadable/component';
import type { IconType } from 'react-icons';

interface IIconComponent {
  icon: string;
  size?: number;
  className?: string;
}
export type IReactIcon = string;

export const IconComponent: React.FC<IIconComponent> = ({ icon, size, className }) => {
  const lib = icon.replace(/([a-z0-9])([A-Z])/g, '$1 $2').split(' ')[0].toLowerCase();
  const DynamicIconComponent = loadable(() => import(`../../../node_modules/react-icons/${lib}/index.js`), {
    resolveComponent: (el) => el[icon],
    fallback: <span className={cn(`h-[${size}px] w-[${size}px]`, className)} />,
  }) as IconType;

  return (<DynamicIconComponent size={size} className={cn('mgsm-icon-component shrink-0', className)} />);
};
