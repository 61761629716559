import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/components/cms/icon-component.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/components/fps-counter.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/components/icons.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/components/navigation/animated-header.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/components/notifications/first-visit-notification.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/components/theme/theme-provider.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/components/ui/interactive/infinite-scrolling-content.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/components/ui/sonner.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/components/ui/tooltip.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/lib/consumer.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/lib/context/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"fontInter\"}");
import(/* webpackMode: "eager" */ "/usr/src/app/apps/web/src/styles/globals.css");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/nextjs-toploader@3.6.15_next@14.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react-dom@18_d4q76roc7jprsr5xqprnwocewm/node_modules/nextjs-toploader/dist/index.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/react-lite-youtube-embed@2.4.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-lite-youtube-embed/dist/LiteYouTubeEmbed.css")