import React from 'react';

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
} from '@/components/ui/drawer';
import { cmsImageUrl, cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { MenuIcon, XIcon } from 'lucide-react';
import Image from 'next/image';
import LinkButton from '../buttons/link-button';
import { NavConfiguration, SiteConfiguration } from '@repo/api-types/generated';
import ProfileButton from '../auth/profile-button';
import Link from 'next/link';
import { IconComponent } from '../cms/icon-component';

const MobileNav = ({
  className,
  siteConfiguration,
  navConfiguration,
}: {
  className?: string;
  siteConfiguration: SiteConfiguration;
  navConfiguration: NavConfiguration;
}) => {  
  const [open, setOpen] = React.useState(false);
  const clickHandler = () => {
    setOpen(false);
  };
  return (
    <Drawer direction='top' open={open} onOpenChange={setOpen}>
      <div>
        <DrawerTrigger asChild className='mgsm-mobile-nav-trigger'>
          <Button variant="ghost" size="icon" className={cn(className)}>
            <MenuIcon className="h-6 w-6 text-primary" />
          </Button>
        </DrawerTrigger>
      </div>

      <DrawerContent hideBar className={cn(
        'mgsm-mobile-nav',
        'bg-background/85 backdrop-blur-sm',
      )}>
        <ScrollArea className='h-screen'>
          <div className='container'>
            <div className="px-2 mx-auto">
              {/* <MainNavBranding className='flex items-center justify-center' /> */}
              <DrawerClose asChild className={cn(
                'absolute top-2 right-2 cursor-pointer duration-300',
              )}>
                <XIcon className="h-7 w-7 hover:text-primary hover:scale-125" />
              </DrawerClose>
            </div>
            {siteConfiguration.attributes.banner?.data && <div className='flex flex-col gap-4 justify-center items-center py-4 h-[200px]'>
              <Image
                src={cmsImageUrl(siteConfiguration.attributes.banner.data.attributes.url)}
                alt="Logo"
                width={siteConfiguration.attributes.bannerWidthOverwrite ?? siteConfiguration.attributes.banner.data.attributes.width}
                height={siteConfiguration.attributes.bannerHeightOverwrite ?? siteConfiguration.attributes.banner.data.attributes.height}
                onDragStart={(e) => e.preventDefault()}
                loading='eager'
                className='rounded h-full w-auto'
              />
            </div>}

            <p className={cn(
              'text-center text-lg font-bold',
              'p-4 border-t border-muted',
              !siteConfiguration.attributes.banner  ? 'py-10 text-2xl' : null
            )}>
              {siteConfiguration.attributes.name}
            </p>
            <div className={cn(
              'flex flex-col gap-4 flex-grow h-max',
              'p-4 border-t border-muted',
            )}>
              <ProfileButton className='mx-auto' showThemeSwitcher={
                siteConfiguration.attributes.theme?.forceTheme ? false : siteConfiguration.attributes.theme?.showThemeSwitcher ?? true
              } />

              {/* <LinkButton
                href='/contact'
                variant="ghost"
                size="lg"
                className='w-fit mx-auto gap-1 text-lg font-semibold'
                onClick={clickHandler}
              >
                <PhoneIcon className='h-6 w-6 text-primary' />
                Contact Us
              </LinkButton> */}

              {!navConfiguration.attributes.about.hidden && navConfiguration.attributes.about.items.map((item, index) => (
                <LinkButton
                  key={index}
                  href={item.url}
                  variant="ghost"
                  size="lg"
                  className='w-fit mx-auto gap-1 text-lg font-semibold'
                  onClick={clickHandler}
                  target={item.target}
                  disabled={item.disabled}
                >
                  {item.icon && <IconComponent icon={item.icon} size={24} className='h-6 w-6 text-primary' />}
                  {item.title}
                </LinkButton>
              ))}

              {navConfiguration.attributes.additionalButtons?.length > 0 ? navConfiguration.attributes.additionalButtons.map((item, index) => (
                <LinkButton
                  key={index}
                  href={item.url}
                  variant="ghost"
                  size="lg"
                  className='w-fit mx-auto gap-1 text-lg font-semibold'
                  onClick={clickHandler}
                  target={item.target}
                  disabled={item.disabled}
                >
                  {item.icon && <IconComponent icon={item.icon} size={24} className='h-6 w-6 text-primary' />}
                  {item.title}
                </LinkButton>
              )) : null}
            </div>

            {!navConfiguration.attributes.explore.hidden && <>
              <p className={cn(
                'text-center text-lg font-bold',
                'p-4 border-t border-muted',
              )}>
                Explore
              </p>
              <div className={cn(
                'flex flex-col gap-4 flex-grow h-max',
                'p-4 border-t border-muted',
              )}>
                {navConfiguration.attributes.explore.items.map((item, index) => (
                  <LinkButton key={index} href={item.url}
                    variant="ghost"
                    size="lg"
                    className='w-fit mx-auto gap-1 text-lg font-semibold'
                    onClick={clickHandler}
                    target={item.target}
                    disabled={item.disabled}
                  >
                    {item.icon && <IconComponent icon={item.icon} size={24} className='h-6 w-6 text-primary' />}
                    {item.title}
                  </LinkButton>
                ))}
              </div>
            </>}

            <p className={cn(
              'text-center text-lg font-bold',
              'p-4 border-t border-muted',
            )}>
              Socials
            </p>
            <div className={cn(
              'flex flex-col gap-4 flex-grow h-max',
              'p-4 border-t border-muted',
            )}>
              {siteConfiguration.attributes.socials.map((item, index) => (
                <LinkButton key={index} href={item.url}
                  variant="ghost"
                  size="lg"
                  className='w-fit mx-auto gap-1 text-lg font-semibold'
                  onClick={clickHandler}
                  target={item.target}
                  disabled={item.disabled}
                >
                  {item.icon && <IconComponent icon={item.icon} size={24} className='h-6 w-6 text-primary' />}
                  {item.title}
                </LinkButton>
              ))}
            </div>
          </div>
          <DrawerFooter className='container max-w-sm border-t border-muted text-center'>
            Powered by <Link
              className='text-primary flex items-center justify-center gap-2'
              target='_blank'
              rel="noopener noreferrer"
              href='https://mirasaki.dev/'
            >
              MGSM
            </Link>
          </DrawerFooter>
        </ScrollArea>
      </DrawerContent>
    </Drawer>);
};

export default MobileNav;
