'use client';

import { useRouter } from 'nextjs-toploader/app';
import { useEffect } from 'react';

export const ConsumeCookiesAndRedirects = () => {
  const router = useRouter();
  useEffect(() => {
    const consumeSteamLinkRedirect = localStorage.getItem('steam-login-return-to');
    if (consumeSteamLinkRedirect) {
      localStorage.removeItem('steam-login-return-to');
      router.push(consumeSteamLinkRedirect);
    }
  }, [router]);

  return null;
};
