'use client';

import React, { useEffect } from 'react';
import { toastNotification } from './toast';
import { LogoIcon } from '../icons';
import { useRouter } from 'nextjs-toploader/app';
import { SiteConfiguration } from '@repo/api-types/generated';

const FirstVisitNotification = ({ siteConfiguration }: {
  siteConfiguration: SiteConfiguration;
}) => {
  const data = siteConfiguration.attributes.firstVisitNotification;
  const router = useRouter();
  useEffect(() => {
    const firstVisitNotificationSent = localStorage.getItem('first-visit-notification-sent');
    if (!firstVisitNotificationSent) {
      toastNotification(siteConfiguration, data.title, {
        className: 'mgsm-first-visit-notification',
        icon: <LogoIcon siteConfiguration={siteConfiguration} />,
        description: data.description,
        actionButtonStyle: {
          color: 'white',
        },
        action: data.action ? {
          label: data.action.label,
          onClick: () => {
            if (data.action) router.push(data.action.url);
          },
        } : undefined,
      });
      localStorage.setItem('first-visit-notification-sent', new Date().toISOString());
    }

    return () => {
    };
  }, [siteConfiguration, data.action, data.description, data.title, router]);

  return null;
};

export default FirstVisitNotification;
