'use client';

import {
  motion,
  useMotionTemplate,
  useMotionValue,
  useScroll,
  useTransform,
} from 'framer-motion';

import Image from 'next/image';
import { useEffect } from 'react';
import Link from 'next/link';
import MobileNav from './mobile-nav';
import { cmsLogo, cn } from '@/lib/utils';
import MegaMenu from './mega-menu';
import ProfileButton from '../auth/profile-button';
import { NavConfiguration, SiteConfiguration } from '@repo/api-types/generated';
import { StoreIcon } from 'lucide-react';
import { buttonVariants } from '../ui/button';

function useBoundedScroll(threshold: number) {
  const { scrollY } = useScroll();
  const scrollYBounded = useMotionValue(0);
  const scrollYBoundedProgress = useTransform(
    scrollYBounded,
    [0, threshold],
    [0, 1]
  );

  useEffect(() => {
    return scrollY.on('change', (current) => {
      const previous = scrollY.getPrevious();
      const diff = typeof previous === 'number'
        ? current - previous
        : current;
      const newScrollYBounded = scrollYBounded.get() + diff;

      scrollYBounded.set(clamp(newScrollYBounded, 0, threshold));
    });
  }, [threshold, scrollY, scrollYBounded]);

  return { scrollYBounded, scrollYBoundedProgress };
}

export type HeaderProps = {
  items?: { title: string; href: string; description: string; }[];
  className?: string;
  useGutter?: boolean;
  siteConfiguration: SiteConfiguration;
  navConfiguration: NavConfiguration;
};

export default function Header({
  className,
  useGutter = false,
  siteConfiguration,
  navConfiguration,
}: HeaderProps) {
  // Note: 80px is exactly h-20 in Tailwind CSS
  // This is the threshold where the header will shrink
  const { scrollYBoundedProgress } = useBoundedScroll(120);
  const scrollYBoundedProgressDelayed = useTransform(
    scrollYBoundedProgress,
    [0, 1, 1],
    [0, 0, 1]
  );

  const logo = cmsLogo(siteConfiguration, 60);

  return (
    <>
      <motion.header
        style={{
          boxShadow: useMotionTemplate`0 1px 2px 0 rgba(0, 0, 0, ${useTransform(
            scrollYBoundedProgressDelayed,
            [0, 1],
            [0, 0.1]
          )})`,
        }}
        className={cn(
          'mgsm-header',
          'z-50 sticky top-0 inset-x-0 flex duration-300 border-b-primary border-b-[5px] bg-background/75 backdrop-blur-md',
          className
        )}
      >
        <div className="mx-auto flex w-full max-w-5xl items-center justify-between px-8">
          <div
            className="flex origin-left items-center text-xl font-semibold uppercase duration-300"
          >
            <Link href="/" onDragStart={(e) => e.preventDefault()}>
              {logo ? <Image
                src={logo.url}
                alt="Logo"
                width={logo.width}
                height={logo.height}
                onDragStart={(e) => e.preventDefault()}
                className='p-2'
                style={{
                  width: `${logo.width}px`,
                  height: `${logo.height}px`,
                }}
              /> : <span>{siteConfiguration.attributes.name}</span>}
            </Link>
          </div>
          <nav className="hidden lg:flex space-x-4 text-sm text-gray-600 dark:text-gray-200 duration-300">
            <MegaMenu viewportClassName='right-auto' siteConfiguration={siteConfiguration} navConfiguration={navConfiguration} />
          </nav>
          <Link href={siteConfiguration.attributes.storeOnlyMode ? '/' : '/store'} className={cn(
            buttonVariants({variant: 'default', size: 'sm'}),
            'ml-auto mr-4 text-foreground',
            'border border-primary/50 bg-gray-100 dark:bg-gray-950',
            'hover:bg-primary/10 dark:hover:bg-primary/15',
            'focus:bg-primary/10 dark:focus:bg-primary/15',
            'lg:hidden',
          )}>
            <StoreIcon className='h-4 w-4 mr-2 text-primary' />
            Store
          </Link>
          <motion.div className='duration-300 flex lg:hidden flex-row items-center justify-center gap-2'
            style={{
              scale: useTransform(
                scrollYBoundedProgressDelayed,
                [0, 1],
                [1.15, 0.9]
              ),
              rotate: useTransform(
                scrollYBoundedProgressDelayed,
                [0, 1],
                [0, 180]
              ),
            }}
          >
            <MobileNav className="bg-none border-none" siteConfiguration={siteConfiguration} navConfiguration={navConfiguration} />
          </motion.div>
          {/* Note: Move INSIDE motion.div for drastic layout shift */}
          <ProfileButton className='hidden lg:block border-none' showThemeSwitcher={
            siteConfiguration.attributes.theme?.forceTheme ? false : siteConfiguration.attributes.theme?.showThemeSwitcher ?? true
          } />
        </div>
      </motion.header>

      {/* Gutter */}
      {useGutter && <div className="h-20 w-full" />}
    </>
  );
}

const clamp = (number: number, min: number, max: number) =>
  Math.min(Math.max(number, min), max);
