'use client';

import { useUser } from '../swr';
import { AuthContext } from './auth-context';
import { ReactNode } from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { SWRConfig } from 'swr';
import { TouchProvider } from '../../components/ui/hybrid-tooltip';

if (
  process.env.NEXT_PUBLIC_IS_PAYPAL_ENABLED === 'true'
  && !process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID
) {
  throw new Error('PayPal client ID is required');
}

export const ContextProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const { data, loading, error } = useUser();

  const ConditionalPayPalProvider = ({children}: {children: ReactNode}) => (
    process.env.NEXT_PUBLIC_IS_PAYPAL_ENABLED === 'true'
      && process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID
      ? <PayPalScriptProvider options={{
        clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
        currency: process.env.NEXT_PUBLIC_CURRENCY_CODE,
      }}>
        {children}
      </PayPalScriptProvider >
      : children
  );

  return (<SWRConfig value={{
    revalidateOnFocus: false,
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
  }}>
    <AuthContext.Provider value={{
      data,
      loading,
      error: error === null ? null : error.message,
    }}>
      <ConditionalPayPalProvider>
        <TouchProvider>
          {children}
        </TouchProvider>
      </ConditionalPayPalProvider>
    </AuthContext.Provider>
  </SWRConfig>);
};
