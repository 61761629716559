'use client';

import { Constants } from '@repo/constants';
import { useEffect } from 'react';

export function FPSCounter() {
  useEffect(() => {
    const fpsCounter = document.getElementById('fps-counter');
    let startTime = Date.now();
    let frame = 0;
    
    function tick() {
      const time = Date.now();
      frame++;
      if (!fpsCounter) return;
      if (time - startTime > Constants.MS_IN_ONE_SECOND) {
        fpsCounter.textContent = `FPS: ${(frame / ((time - startTime) / Constants.MS_IN_ONE_SECOND)).toFixed(2)}`;
        startTime = time;
        frame = 0;
      }
      window.requestAnimationFrame(tick);
    }
    tick();
  });

  if (process.env.NODE_ENV !== 'development') return null;

  return (
    <p className="mgsm-fps-counter fixed m-10 top-0 left-0 z-50 pointer-events-none" id="fps-counter">
    </p>
  );
}
